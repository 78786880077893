import { createComponent, Flex, For, If, IntrinsicProps } from 'react-commons';
import Link from 'next/link';
import Image from 'next/image';

import style from './index.module.scss';
import React, { useState } from 'react';
import { DAILY_GAME_QUERY, DailyGameFields, getDailyGameSrc } from '@/lib/drupal/models/Games';
import { DailyArchiveItem, dailyFieldsToDate } from '@/lib/hooks/useDailyArchive';
import { format } from 'date-fns';
import { useIsPremiumUser } from '@/lib/hooks/useIsPremiumUser';
import { getZonedDate } from '@/lib/util/zonedTime';
import { getUrlTarget } from '@/components/DailyGame';
import { CaptureClickEvent } from '@/lib/util/captureClickEvent';

export function getThumbnail (fields: DailyGameFields, contentUrl: string, kind: string = 'daily') {
  const month = fields.month.length === 1
    ? '0' + fields.month
    : fields.month;
  const year = fields.year;
  const day = fields.day.charAt(0) === '0' 
    ? fields.day.replace('0', '') 
    : fields.day;
  return `${contentUrl}/${month}_${year}/${kind}${day}.jpg`;
}
 
export function formatDate (fields: DailyGameFields) {
  const date = dailyFieldsToDate(fields);
  // Sun, May 2
  return format(date, 'EEE, MMM d');
}

function formatYear (fields: DailyGameFields) {
  const date = dailyFieldsToDate(fields);
  // 2021
  return format(date, 'yyyy');
}

interface DailyArchiveThumbnailProps extends IntrinsicProps {
  item: DailyArchiveItem
  contentUrl: string
  kind: 'daily' | 'bonus'
}

const DailyArchiveThumbnail = createComponent<DailyArchiveThumbnailProps>('DailyArchive__Thumbnail', { style }, function DailyArchive__Thumbnail ({ className }, props) {
  const [ isOpen, setOpen ] = useState(false);

  return (
    <div className={className + (isOpen ? ' --ready' : '')}>
      <Image
        src={getThumbnail(props.item.fields, props.contentUrl, props.kind)}
        alt={`${formatDate(props.item.fields)} thumbnail`}
        width={272 * .3}
        height={182 * .3}
        onLoadingComplete={() => setOpen(true)}
      />
      <Image
        src={getThumbnail(props.item.fields, props.contentUrl, props.kind)}
        alt={`${formatDate(props.item.fields)} thumbnail`}
        width={272}
        height={182}
      />
    </div>
  );
});

export interface DailyArchiveProps extends IntrinsicProps {
  slug: string
  gameId: string
  gameTitle: string
  thumbnailUrl?: string
  contentUrl: string
  hasPicture: boolean
  isJigsaw?: boolean
  displayYear?: boolean
  rows: DailyArchiveItem[][]
  kind?: 'daily' | 'bonus'
}

export default createComponent<DailyArchiveProps>('DailyArchive', { style }, function DailyArchive ({ className }, props) {
  const [ isPremiumUser ] = useIsPremiumUser();
 
  const getHref = (fields: DailyGameFields, isToday?: boolean) => {
    if (props.isJigsaw && !isToday && !isPremiumUser) return '/unlimited';
    if (props.rows.length >= 21 && !isToday && !isPremiumUser) return '/unlimited';
    
    const rootSrc = props.isJigsaw 
      ? '/gameplayer' 
      : '/gamelanding';

    const slug = props.isJigsaw
      ? (
        props.kind === 'bonus'
          ? 'bonusjigsaw'
          : 'dailyjigsaw'
      )
      : props.slug;

    if (isToday) {
      return rootSrc + `/${slug}`;
    }

    return getDailyGameSrc(
      rootSrc + `/${slug}?${DAILY_GAME_QUERY}`, 
      fields
    );
  };

  const isToday = (fields: DailyGameFields) => {
    const today = getZonedDate();
    const date = dailyFieldsToDate(fields);
    return today.toLocaleDateString('en-US') === date.toLocaleDateString('en-US');
  };

  return (
    <div className={className}>
      {
        For(props.rows, (row: DailyArchiveItem[], k) => (
          <Flex className={'DailyArchive__Row ' + (props.hasPicture ? '' : '--hasBorder')} justifySpaceAround>
            {
              For(row, (item: DailyArchiveItem, i) => (
                <CaptureClickEvent
                  key={i}
                  action='upsell'
                  className={`upsell_archive_individual${k}-i${i}`}
                  location='daily_archive_images'
                  disabled={!(getHref(item.fields, isToday(item.fields)).includes('/unlimited'))}
                  properties={() => ({
                    tag_name: 'upsell_archive_individual',
                    id: props.gameId,
                    title: props.gameTitle,
                    view_node: props.slug,
                  })}
                >
                  {
                    If(item.fields, () => (
                      <Link 
                        href={getHref(item.fields, isToday(item.fields))} 
                        key={getHref(item.fields)}
                      >
                        {
                          If(props.hasPicture, () => (
                            <a 
                              className={(isToday(item.fields) ? '--today' : '') + ` upsell_archive_individual${k}-i${i}`}
                              target={getUrlTarget(getHref(item.fields, isToday(item.fields)))}
                            >
                              <DailyArchiveThumbnail
                                item={item}
                                contentUrl={props.contentUrl}
                                kind={props.kind}
                              />
                              <em>{formatDate(item.fields)}</em>
                              {
                                If(props.displayYear, () => (
                                  <em>{formatYear(item.fields)}</em>
                                )).EndIf()
                              }
                            </a>
                          )).Else(() => (
                            <a 
                              className={'RouterLink ' + (isToday(item.fields) ? '--today' : '') + ` upsell_archive_individual${k}-i${i}`}
                              target={getUrlTarget(getHref(item.fields, isToday(item.fields)))}
                            >
                              <em>{formatDate(item.fields)}</em>
                              {
                                If(props.displayYear, () => (
                                  <em>{formatYear(item.fields)}</em>
                                )).EndIf()
                              }
                            </a>
                          )).EndIf()
                        }
                      </Link>
                    ))
                      .Else(() => (
                        <div className='DailyArchive__Spacer' />
                      ))
                      .EndIf()
                  }
                </CaptureClickEvent>
              ))
            }
          </Flex>
        ))
      }
    </div>
  );
});