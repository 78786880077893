import { FullGameData } from '@/lib/drupal/models/Games';
import { DailyArchiveItem, dateToDailyFields } from '@/lib/hooks/useDailyArchive';
import { getZonedDate } from '@/lib/util/zonedTime';
import { useEffect, useState } from 'react';

export const NUM_ROWS_PREVIEW_LINK_ONLY = 2;
export const NUM_ROWS_PREVIEW = 2;
export const NUM_ROWS_FULL = 5;

interface UseWeeklyArchiveOptions {
  archive: FullGameData[ 'archive' ]
  isFullArchive?: boolean
}

function makeRows (numRows: number, startDate: Date, offset: number = 0) {
  // Construct an array of arrays of DailyArchiveItems, one for each week, starting on the week after the startDate
  // offset is used to offset the start date by a certain number of weeks
  const currentDay = getZonedDate();

  // Set current day to the day of startDate
  currentDay.setDate(currentDay.getDate() + (startDate.getDay() - currentDay.getDay()));

  // If currentDay is in the future, offset by minus one week
  if (currentDay.getTime() > getZonedDate().getTime()) {
    offset += 1;
  }
  
  // Offset by the specified number of weeks
  currentDay.setDate(currentDay.getDate() - (offset * 7)); // offset in weeks * 7 days per week
  
  const rows: DailyArchiveItem[][] = [];
  
  for (let i = 0; i < numRows; i++) {
    if (currentDay < startDate) break;

    const row: DailyArchiveItem[] = [];
    
    for (let j = 0; j < 7; j++) {
      if (currentDay.getTime() < startDate.getTime()) break;

      row.push({
        fields: dateToDailyFields(currentDay),
      });
      
      currentDay.setDate(currentDay.getDate() - 7);
    }
    
    rows.push(row);
  }
  
  return rows;
}

function getNumPages (startDate: Date, numRows: number) {
  const today = getZonedDate();
  today.setDate(today.getDate() + (startDate.getDay() - today.getDay()));

  const diffTime = Math.abs(today.getTime() - startDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.ceil(diffDays / 7);

  return Math.ceil((diffWeeks / 7) / numRows); // 7 items per row
}

export function useWeeklyArchive (options: UseWeeklyArchiveOptions) {
  const numRows = options.archive.display === 'list'
    ? NUM_ROWS_PREVIEW_LINK_ONLY
    : NUM_ROWS_PREVIEW;

  const [ rows, setRows ] = useState<DailyArchiveItem[][]>([]);
  const [ page, setPage ] = useState(0);

  const startDate = new Date(options.archive.startDate);

  const changePage = (nextPage: number) => {
    setPage(nextPage);
  };

  const numPages = () => {
    return getNumPages(
      startDate, 
      numRows
    );
  };

  useEffect(() => {
    setRows(
      makeRows(
        numRows, 
        startDate, 
        page * numRows
      )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page ]);

  useEffect(() => {
    if (page === 0) return;
    setPage(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ options.archive ]);

  return {
    rows,
    page,
    numPages,
    changePage,
  };
}
