import { DailyGameFields, FullGameData } from '@/lib/drupal/models/Games';
import { getFieldsFromDateQuery } from '@/lib/util/gamePlayerTools';
import { getZonedDate } from '@/lib/util/zonedTime';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const NUM_ROWS_PREVIEW_LINK_ONLY = 2;
export const NUM_ROWS_PREVIEW = 3;
export const NUM_ROWS_FULL = 5;

interface UseDailyArchiveOptions {
  archive: FullGameData[ 'archive' ]
  useShortFormat?: boolean
  isFullArchive?: boolean
}

export interface DailyArchiveItem {
  date?: Date
  fields?: DailyGameFields
}

export function dateToDailyFields (date: Date) {
  return {
    day: date.getDate().toString().padStart(2, '0'),
    month: (date.getMonth() + 1).toString().padStart(2, '0'),
    year: date.getFullYear().toString().slice(-2),
  };
}

export function dailyFieldsToDate (fields: DailyGameFields) {
  const date = getZonedDate();
  date.setFullYear(parseInt('20' + fields.year));
  date.setMonth(parseInt(fields.month) - 1);
  date.setDate(parseInt(fields.day));

  return date;
}

function makePreviewRows (numRows: number, isShortFormat: boolean, offset = 0): DailyArchiveItem[][] {
  const rows: DailyArchiveItem[][] = [];
  
  // Calculate the initial offset in days to the start of the first row
  // Since the 4th item (0-based index) of the 2nd row is the target, 
  // we set the initial offset to be -10 (i.e., -7 days for the 1st row and -3 days for the items before the 4th item in the 2nd row)
  const initialDayOffset = isShortFormat
    ? -6 - (numRows - 1) * 7 + offset * numRows * 7 // list style
    : -3 + offset * numRows * 7; // image style

  for (let i = 0; i < numRows; i++) {
    const row: DailyArchiveItem[] = [];
    for (let j = 0; j < 7; j++) {
      const date = getZonedDate();
      
      const daysFromToday = initialDayOffset + j + i * 7;
      date.setDate(date.getDate() + daysFromToday);

      row.push({
        fields: dateToDailyFields(date),
      });
    }
    rows.push(row);
  }

  if (isShortFormat) {
    rows.forEach(row => row.reverse());
    rows.reverse();
  }

  return rows;
}

function makeArchiveRows (numRows: number, startDate: Date, offset = 0): DailyArchiveItem[][] {
  const rows: DailyArchiveItem[][] = [];
  const todaysDate = getZonedDate();
  offset = offset * numRows;

  for (let i = 0; i < numRows; i++) {
    const row: DailyArchiveItem[] = [];
    for (let j = 0; j < 7; j++) {
      const index = i + offset;
      const date = getZonedDate(todaysDate);

      const nextDate = date.getDate() - (j + (index * 7));
      date.setDate(nextDate);

      if (date < startDate) {
        row.push({});
        continue;
      }
      
      row.push({
        fields: dateToDailyFields(date),
      });
    }
    rows.push(row);
  }

  return rows;
}

function getNumPages (startDate: Date, numRows: number) {
  const today = getZonedDate();
  const diffTime = Math.abs(today.getTime() - startDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return Math.ceil((diffDays / 7) / numRows);
}

export function useDailyArchive (options: UseDailyArchiveOptions) {
  const [ rows, setRows ] = useState<DailyArchiveItem[][]>([]);
  const [ page, setPage ] = useState(0);

  const startDate = new Date(options.archive.startDate);

  const useShortFormat = options.useShortFormat || options.archive.display === 'list';

  const changePage = (nextPage: number) => {
    setPage(nextPage);
  };

  const numPages = () => {
    const numRows = options.isFullArchive 
      ? NUM_ROWS_FULL 
      : useShortFormat
        ? NUM_ROWS_PREVIEW_LINK_ONLY
        : NUM_ROWS_PREVIEW;

    return getNumPages(
      startDate, 
      numRows
    );
  };

  useEffect(() => {
    const numRowsPreview = useShortFormat
      ? NUM_ROWS_PREVIEW_LINK_ONLY
      : NUM_ROWS_PREVIEW;

    setRows(
      options.isFullArchive
        ? makeArchiveRows(NUM_ROWS_FULL, startDate, page)
        : makePreviewRows(numRowsPreview, useShortFormat, page)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page ]);

  useEffect(() => {
    if (page === 0) return;
    setPage(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ options.archive ]);

  return {
    rows,
    page,
    numPages,
    changePage,
  };
}

export function useCurrentDay () {
  const router = useRouter();
  const [ , fields ] = getFieldsFromDateQuery(router.query.date as string);
  const today = dailyFieldsToDate(fields);

  return [ fields, today ] as [ DailyGameFields, Date ];
}
