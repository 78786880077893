import { AuthState, useAuthStore } from '@/lib/drupal/stores/auth';
import { useEffect, useState } from 'react';

export function useIsPremiumUser () {
  const [ authState ] = useAuthStore();

  const checkIsPremiumUser = (state: AuthState) => {
    return state.ready 
      ? state.user?.isPremiumUser 
      : false;
  };

  const [ isPremiumUser, setIsPremiumUser ] = useState(checkIsPremiumUser(authState));
  const [ isLoading, setIsLoading ] = useState(!authState.ready);

  useEffect(() => {
    setIsPremiumUser(checkIsPremiumUser(authState));
    setIsLoading(!authState.ready);
  }, [ authState ]);

  return [ 
    isPremiumUser, 
    isLoading
  ];
}
