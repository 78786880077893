import GamesModel, { DailyGameFields, FullGameData, getDailyGameSrc } from '@/lib/drupal/models/Games';
import { getZonedDate } from '@/lib/util/zonedTime';

export interface GamePlayerPageProps {
  view: 'standard' | 'fullscreen'
  gameHref: string
  game: FullGameData
  isSwuExclusive: boolean
}

export function getFieldsFromDateQuery (date?: string) {
  const today = getZonedDate();
  const todaysFields: DailyGameFields = { 
    year: today.getFullYear().toString().slice(-2), 
    month: (today.getMonth() + 1).toString(), 
    day: today.getDate().toString()
  };

  let fields: DailyGameFields = undefined;
  if (date) {
    const [ year, month, day ] = (date as string).split('/');
    fields = { year, month, day };
  } else {
    fields = todaysFields;
  }

  return [ todaysFields, fields ];
}

export async function getGamePlayerServerProps (context, isMobile?: boolean) {
  const getGameUrl = (mobileUrl: string, desktopUrl: string) => {
    return isMobile 
      ? (mobileUrl || desktopUrl) 
      : desktopUrl;
  };

  const slug = context.params.slug as string;
  const [ todaysFields, fields ] = getFieldsFromDateQuery(context.query.date);
    
  const gameSlugs = {
    'dailyjigsaw': 'jigsawpuzzles',
    'bonusjigsaw': 'bonusjigsaws',
    'challengejigsaw': 'jigsawpuzzlechallenge',
  };

  let game: FullGameData;
  let gameHref = '';

  if (!gameSlugs[ slug ]) {
    try {
      game = await GamesModel.getBySlug('gamelanding/' + slug);
    } catch (err) {
      return {
        notFound: true,
      };
    }
    
    if (!game) {
      return {
        notFound: true,
      };
    } else {
      gameHref = getDailyGameSrc(
        getGameUrl(game.embed.mobileSrc, game.embed.src), 
        fields
      );
    }
  } 
  
  else {
    try {
      game = await GamesModel.getBySlug('gamelanding/' + gameSlugs[ slug ]);
    } catch (err) {
      return {
        notFound: true,
      };
    }

    gameHref = getDailyGameSrc(
      getGameUrl(game.embed.mobileSrc, game.embed.src),
      fields
    );
  }

  const isToday = 
    fields.year === todaysFields.year && 
    fields.month === todaysFields.month && 
    fields.day === todaysFields.day;

  const isSwuExclusive = isToday
    ? (
      (game.pageLayout === 'jigsaw' && slug !== 'dailyjigsaw') ||
      (game.pageLayout !== 'jigsaw' && game.isSwuExclusive)
    )
    : (
      context.query.date ||
      slug === 'challengejigsaw' ||
      slug === 'bonusjigsaw' ||
      (game.pageLayout !== 'jigsaw' && game.isSwuExclusive)
    );
    

  const props: GamePlayerPageProps = {
    view: isSwuExclusive ? 'fullscreen' : 'standard',
    gameHref,
    game,
    isSwuExclusive,
  };

  return {
    props
  };
}
